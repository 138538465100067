import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CouponService } from 'src/app/services/coupons/coupon.service';

@Component({
  selector: 'app-greetings',
  templateUrl: './greetings.component.html',
  styleUrls: ['./greetings.component.css'],
})
export class GreetingsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private couponService: CouponService
  ) {}

  ngOnInit(): void {
    this.couponService.getCoupon(this.barcodeID);
    this.couponIsValid();
  }

  couponIsValid() {
    this.couponService.coupon.subscribe((data) =>
      this.greetings(data[0].valid)
    );
  }

  greetings(code) {
    console.log(code)
    if (code) this.code_isvalid = 'BON ENCORE VALIDE';
    else this.code_isvalid = 'BON DEJA UTILISÉ';
  }

  barcodeID = this.route.snapshot.paramMap.get('barcode');
  code_isvalid = 'ERREUR MAUVAIS CODE';
}
