import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Coupon } from './coupon.model';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  constructor(public firestore: AngularFirestore) {
    console.log('hello');
    this.coupon = this.firestore
      .collection('fresh_food')
      .doc('offers')
      .collection('coupons')
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as Coupon;
            return { ...data };
          });
        })
      );
  }
  coupon: Observable<any[]>;

  getCoupon(barcode) {
    this.coupon = this.firestore
      .collection('fresh_food')
      .doc('offers')
      .collection('coupons', (ref) =>
      ref.where("__name__", "==", barcode)
    ).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as Coupon;
            return { ...data };
          });
        })
      );
  }
}
