<div class="container">
  <div class="row m-5">
    <div class="col">
      <h3>{{ code_isvalid }}</h3>
    </div>
  </div>
  <div class="row m-5">
    <div
      onclick="location.href='https://www.freshfoodamneville.fr/'"
      class="btn btn-block btn-dark"
    >
      Site web
    </div>
    <div
      onclick="location.href='https://app.resmio.com/fresh-food/widget?fullscreen=true&backgroundColor=%23ece6d0&color=%23000000&commentsDisabled=false&facebookLogin=false&fontSize=14px&height=400&linkBackgroundColor=%23000000&newsletterSignup=true&width=350'"
      class="btn btn-block btn-dark"
    >
      Reserver en ligne
    </div>
    <div
      onclick="location.href='https://www.facebook.com/FreshFoodAmneville'"
      class="btn btn-block btn-dark"
    >
      Facebook
    </div>
    <div
      onclick="location.href='https://www.instagram.com/fresh_food_57/'"
      class="btn btn-block btn-dark"
    >
      Instagram
    </div>
  </div>
</div>
